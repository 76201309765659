@import "svg-url-encoder.scss";
@import "variables-custom.scss";

/*===================================
=            Main navbar            =
===================================*/

@function get-color($colorName) {
    @return map-get($colors, $colorName);
}

#website-navbar {
    font-family: "Roboto Condensed";
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    .navbar-nav {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        width: 100%;
        margin-top: 1rem;
        .nav-item {
            line-height: 1.25rem;
            justify-content: center;
            align-items: stretch;
            flex-grow: 1;
        }
        .nav-link {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            text-transform: uppercase;
            font-size: 0.875rem;
            padding-left: $nav-link-padding-x;
            padding-right: $nav-link-padding-x;
            padding-top: $nav-link-padding-y;
            padding-bottom: $nav-link-padding-y;
            &:hover,
            &:focus,
            &.active {
                text-decoration: none;
                background-color: get-color(gray-700);
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    #website-navbar {
        box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.24);
        .navbar-nav {
            margin-top: 0;
            .nav-link {
                font-size: 0.8125rem;
                padding-left: 0.75rem;
                padding-right: 0.75rem;
                height: 100%;
            }
        }
        .navbar-brand {
            margin-bottom: 0;
            font-size: 1.0625rem;
        }
    }
}

/*===============================
=            Sidebar            =
===============================*/

@function get-color($colorName) {
    @return map-get($colors, $colorName);
}

$navbar-height: 72px;
$navbar-left-width: 230px;
$navbar-left-color: #fff;
$navbar-left-background: $gray-800;
$navbar-left-active-background: get-color(red-600);

#navbar-left-wrapper {
    display: none;
    position: fixed;
    top: $navbar-height;
    padding-right: 0;
    width: $navbar-left-width;
    height: 100%;
    background-color: $navbar-left-background;
    z-index: 2;
    box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.12), 1px 0px 1px rgba(0, 0, 0, 0.24);
    #navbar-left-collapse {
        display: none;
    }
    ~ .container {
        padding-left: $navbar-left-width;
    }
    @include media-breakpoint-up(lg) {
        display: block;
        ~ .container-fluid {
            padding-left: $navbar-left-width + $grid-gutter-width / 2;
        }
    }
}

/* navbar-left md, sm & xs devices*/

@include media-breakpoint-down(md) {
    #navbar-left-wrapper {
        #navbar-left-collapse {
            display: block;
        }
        ~ .container {
            padding-left: 15px;
        }
    }

    .w3-animate-left {
        position: relative;
        animation: animateleft 0.4s;
    }
    @keyframes animateleft {
        from {
            left: -$navbar-left-width;
            opacity: 0;
        }
        to {
            left: 0;
            opacity: 1;
        }
    }
}

#navbar-left {
    z-index: 100;
    position: relative;
    width: 100%;
    color: $navbar-left-color;
    background-color: $navbar-left-background;

    box-shadow: 0 1px 0 rgba(3, 3, 3, 1);

    ul,
    li {
        margin: 0;
        padding: 0;
    }

    > li {
        /* main links*/
        > a {
            padding: 12px 20px 12px 18px;
            border-top: 1px solid lighten($navbar-left-background, 10%);
            border-bottom: 1px solid darken($navbar-left-background, 8%);
            text-shadow: 1px 1px 0 lighten($navbar-left-background, 10%);
            color: $navbar-left-color;
            background-color: lighten($navbar-left-background, 5%);
            font-size: 13px;
            font-weight: 400;

            i {
                line-height: 20px;
            }
            /* active main links*/
            &:hover,
            &.active {
                background-color: darken($navbar-left-active-background, 5%);
            }
        }

        a[aria-expanded="true"],
        a[aria-expanded="false"] {
            i {
                transition: all 0.3s ease-in;
            }

            &[aria-expanded="true"] {
                i {
                    rotate: -180deg;
                }
                ~ ul {
                    border-top: 1px solid #494f4f;
                    border-bottom: 1px solid #212424;
                }
            }
        }

        /* 2nd level*/
        > ul {
            > li {
                /* all secondary levels links*/
                width: 100%;
                a {
                    font-size: 13px;
                    font-weight: 300;
                    line-height: 20px;
                    display: block;
                    padding: 5px 22px 5px 30px;
                    text-decoration: none;
                    text-shadow: none;
                    border-top: 1px solid lighten($navbar-left-background, 15%);
                    border-bottom: 1px solid darken($navbar-left-background, 5%);
                    text-shadow: 1px 1px 0 lighten($navbar-left-background, 10%);
                    color: $navbar-left-color;
                    background-color: lighten($navbar-left-background, 8%);

                    .badge {
                        text-shadow: none;
                        text-transform: uppercase;
                    }

                    i {
                        line-height: 20px;
                        font-size: 18px;
                    }

                    &:hover,
                    &.active {
                        background-color: $navbar-left-active-background;
                    }
                }

                &:first-child a {
                    border-top: none;
                }

                &:last-child a {
                    border-bottom: none;
                }

                /* 3rd level*/
                > ul {
                    li {
                        /* all secondary levels links*/
                        width: 100%;
                        list-style-type: square;
                        a {
                            font-size: 13px;
                            line-height: 20px;
                            display: block;
                            padding: 5px 22px 5px 40px;
                            text-decoration: none;
                            text-shadow: none;
                            color: transparentize($navbar-left-color, 0.25);
                            background-color: lighten($navbar-left-background, 10%);

                            i {
                                line-height: 20px;
                                font-size: 18px;
                            }

                            &:before {
                                display: inline-block;
                                content: "■";
                                padding-right: 7px;
                            }

                            &:hover,
                            &.active {
                                background-color: lighten($navbar-left-active-background, 10%);
                            }
                        }
                    }
                }
            }
        }
    }
}

/*====================================================
=            timeline list + badge-circle            =
====================================================*/

$tl-circle-size: 50px;
$tl-line-height: 1.5em;
$tl-border-width: 4px;

.badge-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: -($tl-circle-size + $tl-border-width)/2;
    top: -($tl-circle-size + $tl-border-width)/2 + 1px;
    height: $tl-circle-size;
    width: $tl-circle-size;
    min-width: $tl-circle-size;
    font-size: $tl-circle-size/3;
    border-radius: 50%;
}

.list-timeline {
    padding-left: $tl-circle-size/2;
    padding-top: $tl-circle-size/2;
    > li {
        border-left: $tl-border-width solid #ddd;
        .timeline-content {
            position: relative;
            top: -$tl-line-height/2;
        }
        &:last-child {
            border-left: $tl-border-width solid transparent;
        }
    }
}

/* Cards (template isotope grid) */

.grid {
    .card {
        display: block;
        position: relative;
        margin: 0 0 2rem 0;
        transition: box-shadow 0.25s;
        border-radius: 2px;
        padding: 20px;
        display: block;
        color: $gray-dark;
        min-height: 60px;

        .badge {
            position: absolute;
            right: 20px;
            font-size: 12px;
            top: 50%;
            margin-top: -9px;
        }

        &.card-indigo {
            background-color: $indigo;

            .badge {
                background: darken($indigo, 15%);
            }
        }

        &.card-pink {
            background-color: $pink;

            .badge {
                background: darken($pink, 15%);
            }
        }

        &.card-info {
            background-color: get-color(info-500);

            .badge {
                background: darken(get-color(info-500), 15%);
            }
        }

        &.card-primary {
            background-color: get-color(primary-500);
            &.deprecated {
                background-color: transparentize(get-color(primary-500), 0.2);
            }

            .badge {
                background: darken(get-color(primary-500), 15%);
            }
        }

        &.card-success {
            background-color: get-color(success-500);

            .badge {
                background: darken(get-color(success-500), 15%);
            }
        }

        .item {
            color: #fff;
            font-size: 1.0625rem;
        }

        &:hover {
            text-decoration: none;
        }

        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

        &.has-icon {
            padding-left: 85px;
            padding-right: 95px;

            &:before {
                font-family: "icomoon";
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.75rem;
                color: #fff;
                border-radius: $border-radius 0 0 $border-radius;
                width: 54px;
                height: 100%;
            }

            &:after {
                content: " ";
                position: absolute;
                top: calc(50% - 6px);
                left: 54px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 6px 0 6px 6px;
            }

            &.card-indigo {
                &:before {
                    text-shadow: 1px 1px 3px darken($indigo, 30%);

                    background: darken($indigo, 15%);
                }

                &:after {
                    border-color: transparent transparent transparent darken($indigo, 15%);
                }

                &:hover {
                    background: lighten($indigo, 7.5%);

                    &:before {
                        background: darken($indigo, 20%);
                    }

                    &:after {
                        border-color: transparent transparent transparent darken($indigo, 20%);
                    }
                }
            }

            &.card-pink {
                &:before {
                    text-shadow: 1px 1px 3px darken($pink, 30%);

                    background: darken($pink, 15%);
                }

                &:after {
                    border-color: transparent transparent transparent darken($pink, 15%);
                }

                &:hover {
                    background: lighten($pink, 7.5%);

                    &:before {
                        background: darken($pink, 20%);
                    }

                    &:after {
                        border-color: transparent transparent transparent darken($pink, 20%);
                    }
                }
            }

            &.card-info {
                &:before {
                    text-shadow: 1px 1px 3px darken(get-color(info-500), 30%);

                    background: darken(get-color(info-500), 15%);
                }

                &:after {
                    border-color: transparent transparent transparent darken(get-color(info-500), 15%);
                }

                &:hover {
                    background: lighten(get-color(info-500), 7.5%);

                    &:before {
                        background: darken(get-color(info-500), 20%);
                    }

                    &:after {
                        border-color: transparent transparent transparent darken(get-color(info-500), 20%);
                    }
                }
            }
            &.card-primary {
                &:before {
                    text-shadow: 1px 1px 3px darken(get-color(primary-500), 30%);

                    background: darken(get-color(primary-500), 15%);
                }

                &:after {
                    border-color: transparent transparent transparent darken(get-color(primary-500), 15%);
                }

                &:hover {
                    background: lighten(get-color(primary-500), 15%);

                    &:before {
                        background: darken(get-color(primary-500), 25%);
                    }

                    &:after {
                        border-color: transparent transparent transparent darken(get-color(primary-500), 25%);
                    }
                }
            }

            &.card-success {
                &:before {
                    text-shadow: 1px 1px 3px darken(get-color(success-500), 30%);

                    background: darken(get-color(success-500), 15%);
                }

                &:after {
                    border-color: transparent transparent transparent darken(get-color(success-500), 15%);
                }

                &:hover {
                    background: lighten(get-color(success-500), 7.5%);

                    &:before {
                        background: darken(get-color(success-500), 20%);
                    }

                    &:after {
                        border-color: transparent transparent transparent darken(get-color(success-500), 20%);
                    }
                }
            }
        }
    }

    .deprecated {
        .card {
            &.card-indigo,
            &.card-info,
            &.card-primary,
            &.card-success {
                h4 {
                    color: darken(get-color(gray-200), 20%);
                }
                background-color: get-color(gray-200);

                .label {
                    background: darken(get-color(gray-200), 15%);
                }
                &.has-icon {
                    &:before {
                        text-shadow: 1px 1px 3px darken(get-color(gray-200), 30%);

                        background: darken(get-color(gray-200), 15%);
                    }

                    &:after {
                        border-color: transparent transparent transparent darken(get-color(gray-200), 15%);
                    }

                    &:hover {
                        background: lighten(get-color(gray-200), 7.5%);

                        &:before {
                            background: darken(get-color(gray-200), 20%);
                        }

                        &:after {
                            border-color: transparent transparent transparent darken(get-color(gray-200), 20%);
                        }
                    }
                }
            }
        }
    }
}

/*============================================
=            responsive card-deck            =
============================================*/

/* Bootstrap 4 breakpoints & gutter*/
$card-grid-breakpoints: (
    xs: 0,
    md: 768px,
    lg: 992px
) !default;

/* $grid-gutter-width: 30px !default;*/

/* number of cards per line for each breakpoint*/
$cards-per-line: (
    xs: 1,
    md: 2,
    lg: 3
);

@each $name, $breakpoint in $card-grid-breakpoints {
    @media (min-width: $breakpoint) {
        .card-deck .card {
            flex: 0 0 calc(#{100 / map-get($cards-per-line, $name)}% - #{$grid-gutter-width});
        }
    }
}

/*=============================================
=            Global caret settings            =
=============================================*/

%dropdown-toggle-after {
    line-height: $font-size-base * 1.5;
    position: absolute;
    top: calc(50% - 7px);
    right: $spacer;
    display: block;
    width: 7px;
    height: 14px;
    margin: 0;
    content: " ";
    transition: transform 0.2s ease-in-out;
    transform: rotate(0deg);
    border: none;
    background-repeat: no-repeat;
}
.dropdown-toggle {
    position: relative;
    padding-right: $spacer * 2.5 !important;
    &:not(.sidebar-toggler):after {
        @extend %dropdown-toggle-after;
    }
    &:not(.dropdown-light):after {
        @include background-svg(
            '<svg aria-hidden="true" data-fa-processed="" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa fa-angle-right fa-w-8 fa-2x"><path fill="#{$body-light-color}" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" class=""></path></svg>'
        );
    }
    &.dropdown-light:after {
        @include background-svg(
            '<svg aria-hidden="true" data-fa-processed="" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa fa-angle-right fa-w-8 fa-2x"><path fill="#{$gray-100}" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" class=""></path></svg>'
        );
    }
    &[aria-expanded="true"]:after {
        transform: rotate(90deg);
    }
}

/*==========================================================================
=            Filetree (http://jsfiddle.net/mehmetatas/fXzHS/2/)            =
==========================================================================*/

.tree li {
    margin: 0 0 !important;
    list-style-type: none;
    position: relative;
    padding: 20px 5px 0px 5px;
    small {
        display: inline-block;
    }
}

.tree li::before {
    content: "";
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    right: auto;
    left: -20px;
    border-left: 1px solid #ccc;
    bottom: 50px;
}

.tree li::after {
    content: "";
    position: absolute;
    top: 30px;
    width: 25px;
    height: 20px;
    right: auto;
    left: -20px;
    border-top: 1px solid #ccc;
}

.tree li a[href="#"] {
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    text-decoration: none;
    color: #666;
    font-family: arial, verdana, tahoma;
    font-size: 11px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;

    &.folder {
        background: #ffe79c;
        border: 1px solid darken(#ffe79c, 30%);
    }

    ~ ul li a.folder ~ ul li a.folder {
        background: lighten(#ffe79c, 10%);
        border: 1px solid darken(#ffe79c, 10%);
        ~ ul li a.folder {
            background: lighten(#ffe79c, 18%);
            border: 1px solid #ffe79c;
        }
    }

    &.file {
        background: lighten(desaturate(get-color(info-500), 20%), 40%);
        border: 1px solid darken(get-color(info-500), 10%);
    }

    ~ ul li a.folder ~ ul li a.folder ~ ul li a.file {
        background: lighten(desaturate(get-color(info-500), 20%), 55%);
        border: 1px solid lighten(get-color(info-500), 18%);
    }
}

/*Remove connectors before root*/
.tree > ul > li::before,
.tree > ul > li::after {
    border: 0;
}

/*Remove connectors after last child*/
.tree li:last-child::before {
    height: 30px;
}

/*Time for some hover effects*/

/*We will apply the hover effect the the lineage of the element also*/
.tree li a:hover,
.tree li a:hover + ul li a {
    &.folder {
        background: darken(#ffe79c, 10%);
        border: 1px solid darken(#ffe79c, 40%);
    }

    &.file {
        background: lighten(desaturate(get-color(info-500), 20%), 30%);
        border: 1px solid darken(get-color(info-500), 20%);
    }

    color: #000;
}

/*Connector styles on hover*/
.tree li a:hover + ul li::after,
.tree li a:hover + ul li::before,
.tree li a:hover + ul::before,
.tree li a:hover + ul ul::before {
    border-color: #94a0b4;
}

/*==============================
=            icomoon            =
==============================*/

@font-face {
    font-family: "icomoon";
    src: url("../fonts/icomoon.eot?rnh868");
    src: url("../fonts/icomoon.eot?rnh868#iefix") format("embedded-opentype"),
        url("../fonts/icomoon.ttf?rnh868") format("truetype"), url("../fonts/icomoon.woff?rnh868") format("woff"),
        url("../fonts/icomoon.svg?rnh868#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    font-family: "icomoon";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-toggle-on:before {
    content: "\e902";
}
.icon-home:before {
    content: "\e901";
}
.icon-pencil2:before {
    content: "\e906";
}
.icon-eyedropper:before {
    content: "\e90a";
}
.icon-paint-format:before {
    content: "\e90c";
}
.icon-images:before {
    content: "\e90e";
}
.icon-cart:before {
    content: "\e93a";
}
.icon-coin-dollar:before {
    content: "\e93b";
}
.icon-lifebuoy:before {
    content: "\e941";
}
.icon-compass2:before {
    content: "\e94a";
}
.icon-calendar:before {
    content: "\e953";
}
.icon-user:before {
    content: "\e971";
}
.icon-users:before {
    content: "\e972";
}
.icon-user-check:before {
    content: "\e975";
}
.icon-spinner4:before {
    content: "\e97d";
}
.icon-search2:before {
    content: "\e986";
}
.icon-key2:before {
    content: "\e98e";
}
.icon-cog:before {
    content: "\e994";
}
.icon-hammer:before {
    content: "\e996";
}
.icon-glass:before {
    content: "\e9a0";
}
.icon-spoon-knife:before {
    content: "\e9a3";
}
.icon-airplane:before {
    content: "\e9af";
}
.icon-truck:before {
    content: "\e9b0";
}
.icon-switch:before {
    content: "\e9b6";
}
.icon-power-cord:before {
    content: "\e9b7";
}
.icon-earth:before {
    content: "\e9ca";
}
.icon-eye:before {
    content: "\e9ce";
}
.icon-eye-plus:before {
    content: "\e9cf";
}
.icon-eye-minus:before {
    content: "\e9d0";
}
.icon-eye-blocked:before {
    content: "\e9d1";
}
.icon-star-empty:before {
    content: "\e9d7";
}
.icon-star-half:before {
    content: "\e9d8";
}
.icon-star-full:before {
    content: "\e9d9";
}
.icon-plus:before {
    content: "\ea0a";
}
.icon-minus:before {
    content: "\ea0b";
}
.icon-info:before {
    content: "\ea0c";
}
.icon-cancel-circle:before {
    content: "\ea0d";
}
.icon-loop2:before {
    content: "\ea2e";
}
.icon-radio-checked:before {
    content: "\ea54";
}
.icon-mail:before {
    content: "\f03b";
}
.icon-mail-read:before {
    content: "\f03c";
}
.icon-search:before {
    content: "\f02e";
}
.icon-newspaper:before {
    content: "\e904";
}
.icon-profile:before {
    content: "\e923";
}
.icon-address-book:before {
    content: "\e944";
}
.icon-user-plus:before {
    content: "\e973";
}
.icon-gift:before {
    content: "\e99f";
}
.icon-arrow-right:before {
    content: "\ea34";
}
.icon-checkbox-unchecked:before {
    content: "\ea53";
}
.icon-upload:before {
    content: "\e961";
}
.icon-database:before {
    content: "\e964";
}
.icon-dynamic:before {
    content: "\e982";
}
.icon-stack:before {
    content: "\e92e";
}
.icon-bubble2:before {
    content: "\e96e";
}
.icon-tree:before {
    content: "\e9bc";
}
.icon-food:before {
    content: "\e600";
}
.icon-phone:before {
    content: "\e601";
}
.icon-car:before {
    content: "\e602";
}
.icon-rocket:before {
    content: "\e603";
}
.icon-chart:before {
    content: "\e604";
}
.icon-comments:before {
    content: "\e605";
}
.icon-check:before {
    content: "\e900";
}
.icon-tag:before {
    content: "\f02b";
}
.icon-check-square-o:before {
    content: "\f046";
}
.icon-chevron-up:before {
    content: "\f077";
}
.icon-chevron-down:before {
    content: "\f078";
}
.icon-key:before {
    content: "\f084";
}
.icon-unlock-alt:before {
    content: "\f13e";
}
.icon-bed:before {
    content: "\f236";
}
.icon-hotel:before {
    content: "\f236";
}

/*==============================
=            alerts            =
==============================*/

.has-icon {
    position: relative;
    &.alert {
        padding-left: 70px;
        &:before {
            padding: 13px 0 0 13px;
            content: " ";
        }
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 50px;
        height: 100%;
        border-radius: 3px 0 0 3px;
        background-repeat: no-repeat;
        background-position: center center;
    }

    &.alert:after {
        position: absolute;
        top: calc(50% - 6px);
        left: 50px;
        width: 0;
        height: 0;
        content: " ";
        border-width: 6px 0 6px 6px;
        border-style: solid;
    }

    &.has-icon-success {
        &:before {
            background-color: #{$success};
            /* check-circle*/

            @include background-svg(
                '<svg aria-hidden="true" data-fa-processed="" data-prefix="fas" data-icon="check-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check-circle fa-w-16"><path fill="#{$white}" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" class=""></path></svg>'
            );
            background-size: 30%;
        }
    }

    &.alert-success {
        &:before {
            background-color: #{$success};
            /* check*/

            @include background-svg(
                '<svg aria-hidden="true" data-fa-processed="" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="#{$white}" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" class=""></path></svg>'
            );
            background-size: 30%;
        }

        &:after {
            border-color: transparent transparent transparent #{$success};
        }

        .var-value,
        .file-path {
            color: white;
            background-color: #{$success};
        }
    }

    &.alert-info {
        &:before {
            color: #{$info};
            background-color: #{$info};

            @include background-svg(
                '<svg aria-hidden="true" data-fa-processed="" data-prefix="fas" data-icon="info" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-info fa-w-6"><path fill="#{$white}" d="M20 424.229h20V279.771H20c-11.046 0-20-8.954-20-20V212c0-11.046 8.954-20 20-20h112c11.046 0 20 8.954 20 20v212.229h20c11.046 0 20 8.954 20 20V492c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20v-47.771c0-11.046 8.954-20 20-20zM96 0C56.235 0 24 32.235 24 72s32.235 72 72 72 72-32.235 72-72S135.764 0 96 0z" class=""></path></svg>'
            );
            background-size: 11.25%; /* 30% * 192/512*/
        }

        &:after {
            border-color: transparent transparent transparent #{$info};
        }

        .var-value,
        .file-path {
            background-color: #{$info};
        }
    }

    &.alert-warning {
        &:before {
            background-color: #{$warning};

            @include background-svg(
                '<svg aria-hidden="true" data-fa-processed="" data-prefix="fas" data-icon="exclamation-triangle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-exclamation-triangle fa-w-18"><path fill="#{$white}" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" class=""></path></svg>'
            );
            background-size: 33.75%; /* 30% * 576/512*/
        }

        &:after {
            border-color: transparent transparent transparent #{$warning};
        }
    }

    &.alert-danger {
        &:before {
            background-color: #{$danger};

            @include background-svg(
                '<svg aria-hidden="true" data-fa-processed="" data-prefix="fas" data-icon="exclamation-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-exclamation-circle fa-w-16"><path fill="#{$white}" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" class=""></path></svg>'
            );
            background-size: 30%;
        }

        &:after {
            border-color: transparent transparent transparent #{$danger};
        }
    }
}

.alert {
    position: relative;
    border: none;

    :first-child {
        margin-top: 0;
    }

    p {
        margin-bottom: 0.5em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        text-decoration: underline;
    }
}

/*====================================================================
=            social - https://codepen.io/migli/pen/mggqoK           =
====================================================================*/

// Settings

// number of social networks
$social-networks: 13;

// number of columns
$colums: 2;

$button-width: 2.5rem;
$button-height: 2.5rem;
$button-animation: all 200ms ease;

.pace-done #share-wrapper {
    display: block;
}

#share-wrapper {
    position: absolute;
    top: 37px;
    z-index: 1050;
    right: 50px;
    height: 40px;
    transform: translateY(-50%);
    &:hover:before {
        position: absolute;
        right: 110%;
        top: 4px;
        content: "Help us & share!";
        display: block;
        white-space: nowrap;
        padding: .33rem 1rem;
        background: #4080ff;
        color: #fff;
    }
    ul {
        width: calc(#{$colums} * 45px);
        position: relative;
        left: calc(-50% + 22.5px);
        li {
            width: 45px;
        }
    }
    li > a,
    label {
        color: #efefef;
        border-radius: 50%;
        text-decoration: none;
        width: $button-width;
        height: $button-height;
        font-size: 0.45;
        cursor: pointer;
        &#share {
            background: #4267b2;
            position: relative;
            z-index: $social-networks + 1;
            opacity: 0.75;
            &:hover {
                background: #4080ff;
            }
        }
        &#share-facebook {
            background: #3b5998;
        }
        &#share-twitter {
            background: #00acee;
        }
        &#share-pinterest {
            background: #e4405f;
        }
        &#share-linkedin {
            background: #0077b5;
        }
        &#share-reddit {
            background: #ff4500;
        }
        &#share-google-bookmarks {
            background: #4285f4;
        }
        &#share-mix {
            background: #ff8226;
        }
        &#share-pocket {
            background: #ee4056;
        }
        &#share-digg {
            background: #2a2a2a;
        }
        &#share-blogger {
            background: #fda352;
        }
        &#share-tumblr {
            background: #35465c;
        }
        &#share-flipboard {
            background: #cc0000;
        }
        &#share-hacker-news {
            background: #ff6600;
        }
    }
    li {
        $i: 1;
        @for $sn from $i through $social-networks {
            $delay: ($sn * 100) / 6;
            &:nth-child(#{$sn}) {
                transition-delay: #{$delay}ms;
                opacity: 0;
                transform: translateY(-30%);
                z-index: $social-networks + $i - $sn;
            }
        }
        transition: $button-animation;
    }
    input:checked {
        ~ label,
        ~ ul li {
            transform: scale(1) translateY(0);
            opacity: 1;
        }
    }
}

@media only screen and (min-width: 992px) {
    #share-wrapper {
        position: fixed;
        top: 230px;
        right: -20px;
    }
}

.fb-like {
    top: 90px;
    left: 80px;
    width: 42px;
    position: absolute !important;
    z-index: 1050;
}

@media only screen and (min-width: 992px) {
    .fb-like {
        position: fixed !important;
        top: 170px;
        right: 38px;
        left: auto;
    }
}

#mailchimp-subscribe {
    position: fixed;
    right: 13px;
    top: 135px;
    color: #fff;
    background: #1877F2;
    padding: 1px 8px;
    border-radius: 2px;
    font-size: 13px;
    &:hover {
        text-decoration: none;
    }
}
