//
//  https://codepen.io/jakob-e/pen/doMoML
//  Function to create an optimized svg url
//  Version: 1.0.6
@function svg-url($svg) {
    //
    //  Add missing namespace
    //
    @if not str-index($svg,xmlns) {
        $svg: str-replace($svg, '<svg', '<svg xmlns="http://www.w3.org/2000/svg"');
    }
    //
    //  Chunk up string in order to avoid
    //  "stack level too deep" error
    //
    $encoded: '';
    $slice: 2000;
    $index: 0;
    $loops: ceil(str-length($svg)/$slice);
    @for $i from 1 through $loops {
        $chunk: str-slice($svg, $index, $index + $slice - 1);
        //
        //   Encode
        //
        $chunk: str-replace($chunk, '"', "'");
        $chunk: str-replace($chunk, '%', '%25');
        $chunk: str-replace($chunk, '#', '%23');
        $chunk: str-replace($chunk, '{', '%7B');
        $chunk: str-replace($chunk, '}', '%7D');
        $chunk: str-replace($chunk, '<', '%3C');
        $chunk: str-replace($chunk, '>', '%3E');

        //
        //    The maybe list
        //
        //    Keep size and compile time down
        //    ... only add on documented fail
        //
        //  $chunk: str-replace($chunk, '&', '%26');
        //  $chunk: str-replace($chunk, '|', '%7C');
        //  $chunk: str-replace($chunk, '[', '%5B');
        //  $chunk: str-replace($chunk, ']', '%5D');
        //  $chunk: str-replace($chunk, '^', '%5E');
        //  $chunk: str-replace($chunk, '`', '%60');
        //  $chunk: str-replace($chunk, ';', '%3B');
        //  $chunk: str-replace($chunk, '?', '%3F');
        //  $chunk: str-replace($chunk, ':', '%3A');
        //  $chunk: str-replace($chunk, '@', '%40');
        //  $chunk: str-replace($chunk, '=', '%3D');

        $encoded: #{$encoded}#{$chunk};
        $index: $index + $slice;
    }
    @return url('data:image/svg+xml,#{$encoded}');
}

//  Background svg mixin
@mixin background-svg($svg) {
    background-image: svg-url($svg);
}

//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    @return if($index,
        str-slice($string, 1, $index - 1) + $replace +
        str-replace(str-slice($string, $index +
        str-length($search)), $search, $replace),
        $string);
}
